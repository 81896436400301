<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Stocker un article

              <router-link to="stocks">
                <span class="float-right btn btn-info">retour</span>
              </router-link>
            </div>
            <div class="container">
              <form @submit.prevent="addstocks()">
                <div class="row" v-if="suivant === 1">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="first"
                        >Choisir l'article <span class="obligatoire">*</span>
                      </label>

                      <v-select
                        :multiple="false"
                        v-model="produit_selected"
                        :closeOnClick="false"
                        :options="produits"
                        :reduce="(produits) => produits"
                        label="reference"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="first"
                        >Quantités<span class="obligatoire">*</span>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        id="quantite_total"
                        v-model="quantite_total"
                        required
                      />
                    </div>
                  </div>

                  <!--  col-md-6   -->

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="first"
                        >Emplacements Disponibles
                        <span class="obligatoire">*</span>
                      </label>

                      <v-select
                        :multiple="true"
                        :closeOnClick="false"
                        v-model="emplacement_selected"
                        :options="emplacements"
                        :reduce="(emplacements) => emplacements"
                        label="code"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <br />
                      <button
                        type="submit"
                        class="btn btn-primary"
                        style="margin-top: 8px"
                        @click.prevent="nextEtape()"
                        :disabled="
                          emplacement_selected.length === 0 ||
                          produit_selected.length === 0
                        "
                      >
                        Suivant
                      </button>
                    </div>
                  </div>

                  <!--  col-md-6   -->
                </div>

                <div v-if="suivant === 2">
                  <div class="row" style="border-bottom: 1px solid black">
                    <div class="col-4">
                      L'article a stocké=<b>{{ produit_selected.reference }}</b>
                    </div>

                    <div class="col-4">
                      Quantités totales du l'article =<b
                        >{{ quantite_calcul }}
                      </b>
                    </div>
                    <!--  col-md-6   -->
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">L'emlacement</th>
                            <th scope="col">Capacité vide</th>

                            <th scope="col">Quantités</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(emplacement, index) in emplacement_selected"
                            :key="index"
                          >
                            <td>{{ emplacement.code }}</td>
                            <td>{{ emplacement.capacite_vide }}</td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="Entrer la quantité"
                                v-model="emplacement.quantites"
                                :max="emplacement.capacite_vide"
                                @change="testQuantite"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>
                              <button
                                type="submit"
                                class="btn btn-primary"
                                style="float: right"
                                :disabled="quantite_calcul != 0"
                              >
                                Stocker l'article
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from "@/axios";

export default {
  data() {
    return {
      produit_selected: "",
      emplacement_selected: "",
      error: "",
      emplacements: [],
      produits: [],
      one_produit: [],
      suivant: 1,
      emplacements_produits: [],
      mm: "",
      quantite_total: "",
      quantite_calcul: 0,
      quantite_minimal: "",
    };
  },

  created() {
    this.getemplacements();
    this.getproduits();

  },
  methods: {
    nextEtape() {
      this.suivant++;
      this.quantite_calcul = this.quantite_total;
    },
    getemplacements() {
      HTTP.get("emplacements/getemplacementsdisponible")
        .then((response) => {
          this.emplacements = response.data;

          this.emplacements.forEach((emplacement, index) => {
            emplacement.quantites = "";
            emplacement.produit_id = "";
          });
        })
        .then((err) => console.log(err));
    },

    getproduits() {
      HTTP.get("produits/getproduits").then(
        (response) => {
          this.produits = response.data;
        },
        (err) => {
          this.error = err.response.data.error;
        }
      );
    },

    testQuantite() {
      this.quantite_calcul = this.quantite_total;

      for (let i = 0; this.emplacement_selected.length > i; i++) {
        console.log(this.emplacement_selected[i].quantites);
        if (this.emplacement_selected[i].quantites) {
          this.quantite_calcul -= this.emplacement_selected[i].quantites;
        }
      }
    },

    addstocks() {
      //ajouter quantités

      this.emplacement_selected.forEach((emplacement, index) => {
        emplacement.produit_id = this.produit_selected._id;
      });

      HTTP.post("stocks/addstocks", this.emplacement_selected).then(
        (response) => {
          if (response.status === 200) {
            Toast.fire({
              icon: "success",
              title: "Produit stocké",
            });
            this.$router.push("/stocks");
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
  },
};
</script>
